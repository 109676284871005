import React from 'react'

const Footer = () => (
    <div id="footer">
        <div className="inner">
            <ul className="icons">
                <li>
                    <a href="https://twitter.com/IscTecnm" target="_blank" className="icon fa-twitter">
                        <span className="label">Twitter</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.facebook.com/ITESCOISC" target="_blank" className="icon fa-facebook">
                        <span className="label">Facebook</span>
                    </a>
                </li>
                <li>
                    <a href="#" target="_blank" className="icon fa-envelope-o">
                        <span className="label">Email</span>
                    </a>
                </li>
            </ul>
            <ul className="copyright">
                <li>&copy; 1devteam</li>
            </ul>
        </div>
    </div>
)

export default Footer
